<template>
  <GenericModal :title="title" size="sm" :config="modalConfig">
    <template #openButton="{ openModal }">
      <button
        @click="openModal"
        class="p-2 focus:ring focus:ring-green-200 rounded-lg hover:opacity-80"
        id="editDeviceButton"
      >
        <font-awesome-icon class="text-xl" :icon="['far', 'pen-to-square']" />
      </button>
    </template>
    <template>
      <div class="flex flex-column p-8" v-if="loading">
        <LoadingSpinner class="m-auto" />
      </div>
      <div class="flex flex-column py-3 px-4 gap-3" v-else>
        <ErrorDisplay v-if="errorMessage" :error="errorMessage" />
        <b-form-group label-for="input-organization-select" v-if="isRbAdmin">
          <template slot="label">Organization <span class="text-danger">*</span></template>
          <OrganizationSelectV2
            inputName="input-organization-select"
            :value="deviceOrganizationId"
            @organizationSelected="organizationSelected"
            :allowEmpty="false"
            ref="multiselect"
          />
        </b-form-group>
        <b-form-group
          id="editDeviceName"
          label-for="input-device-name"
          :invalid-feedback="veeErrors.first('input-device-name')"
        >
          <template slot="label">Device Name <span class="text-danger">*</span></template>
          <b-form-input
            name="input-device-name"
            v-model="deviceName"
            v-validate="{ required: true, min: 5, max: 255 }"
            :state="validateState('input-device-name')"
            :disabled="disableDeviceName"
            aria-describedby="input-device-name-feedback"
            data-vv-as="device name"
            trim
          />
        </b-form-group>
        <b-form-group
          id="editSellerName"
          label-for="input-seller-name"
          :invalid-feedback="veeErrors.first('input-seller-name')"
        >
          <template slot="label">Seller Name</template>
          <b-form-input
            name="input-seller-name"
            v-model="sellerName"
            v-validate="{ max: 255 }"
            :state="validateState('input-seller-name')"
            aria-describedby="input-seller-name-feedback"
            data-vv-as="seller name"
            trim
          />
        </b-form-group>
        <b-form-group label-for="input-serial-number" :invalid-feedback="veeErrors.first('input-serial-number')">
          <template slot="label">Unique Identifier <span class="text-danger">*</span></template>
          <b-form-input
            name="input-serial-number"
            id="editSerialNumber"
            v-model="serialNumber"
            v-validate="{ required: true, min: 3, max: 255 }"
            :state="validateState('input-serial-number')"
            :disabled="disableSerialNumber"
            aria-describedby="input-serial-number-feedback"
            data-vv-as="serial number"
            trim
          />
        </b-form-group>
        <b-form-group label-for="input-model-name" :invalid-feedback="veeErrors.first('input-model-name')">
          <template slot="label">Model Name <span class="text-danger">*</span></template>
          <b-form-select
            name="input-model-name"
            id="editModelName"
            v-model="modelName"
            v-validate="{ required: true }"
            :state="validateState('input-model-name')"
            :disabled="disableModelName"
            aria-describedby="input-model-name-feedback"
            data-vv-as="model name"
            :options="modelNameOptions"
          />
        </b-form-group>
        <b-form-group
          label-for="input-deployment-group"
          :invalid-feedback="veeErrors.first('input-deployment-group')"
          v-if="isRbAdmin"
        >
          <template slot="label">Deployment Group <span class="text-danger">*</span></template>
          <b-form-select
            name="input-deployment-group"
            v-model="deploymentGroup"
            v-validate="{ required: true }"
            :state="validateState('input-deployment-group')"
            aria-describedby="input-deployment-group-feedback"
            data-vv-as="deployment group"
            :options="deploymentGroupOptions"
          />
        </b-form-group>
        <Toggle
          variant="success"
          v-model="active"
          label="Active"
          name="input-active"
          :state="validateState('input-active')"
          @change="active = $event"
        />
      </div>
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <BaseButton variant="secondary" @click.native="closeModal">Cancel</BaseButton>
      <BaseButton
        variant="success"
        :loading="submitting"
        @click.native="submit(closeModal)"
        class="ml-2"
        id="submitButton"
        >Save</BaseButton
      >
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal';
import ErrorDisplay from '@/components/ErrorDisplay';
import OrganizationSelectV2 from '@/components/OrganizationSelectV2';
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import Toggle from '@/components/rbComponents/Toggle.vue';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  components: {
    GenericModal,
    ErrorDisplay,
    OrganizationSelectV2,
    Toggle,
    LoadingSpinner,
    BaseButton
  },
  data() {
    return {
      modalConfig: {
        showHeaderClose: true,
        showCloseModalButton: true
      },
      title: 'Edit Device',
      submitting: false,
      deviceOrganizationId: null,
      organization: null,
      deviceName: null,
      sellerName: null,
      serialNumber: null,
      modelName: null,
      deploymentGroup: null,
      active: null,
      modelNameOptions: ['PAX A920', 'PAX A920 Pro', 'SUNMI T2 Mini', 'SUNMI V2', 'Unknown'],
      deploymentGroupOptions: ['iv-blue-us', 'iv-green-us'],
      isRbAdmin: false,
      isAccountOwner: false
    };
  },
  computed: {
    loading() {
      return this.$store.state.modals.loading;
    },
    errorMessage() {
      return this.$store.state.modals.error;
    },
    disableDeviceName() {
      return !this.isAccountOwner && !this.isRbAdmin;
    },
    disableSerialNumber() {
      return !this.isRbAdmin;
    },
    disableModelName() {
      return !this.isRbAdmin;
    }
  },
  async mounted() {
    const sessionUser = await getAuth().sessionUser();
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);
    this.isAccountOwner = sessionUser.hasRole(Roles.ACCOUNT_OWNER);
    await this.loadDevice();
  },
  methods: {
    async loadDevice() {
      await this.$store.dispatch('devices/readDevice', this.deviceId);
      const device = this.$store.state.devices.device;

      this.deviceOrganizationId = device.organizationId;
      this.deviceName = device.name;
      this.sellerName = device.sellerName;
      this.serialNumber = device.serialNumber;
      this.active = device.active;
      this.modelName = device.info.modelName;
      this.deploymentGroup = device.deploymentGroup;

      this.$store.dispatch('stopModalLoading');
    },
    async submit(closeModal) {
      const valid = await this.$validator.validateAll();

      if (!valid) {
        return;
      }

      this.submitting = true;

      const params = {
        deviceId: this.deviceId,
        body: {
          organizationId: this.organization?.id ? this.organization.id : this.deviceOrganizationId,
          name: this.deviceName,
          serialNumber: this.serialNumber,
          active: this.active,
          info: {
            modelName: this.modelName
          },
          deploymentGroup: this.deploymentGroup,
          sellerName: this.sellerName || null
        },
        isModal: true
      };

      if (!this.isRbAdmin) {
        delete params.body.organizationId;
        delete params.body.serialNumber;
        delete params.body.info;
        delete params.body.deploymentGroup;

        if (!this.isAccountOwner) {
          delete params.body.name;
        }
      }

      await this.$store.dispatch('devices/updateDevice', params);

      if (this.errorMessage) {
        this.submitting = false;
        return;
      } else {
        this.submitting = false;
        this.$emit('deviceUpdated');
        closeModal();
      }
    },
    organizationSelected(organization) {
      this.organization = organization;
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>

<style>
.lift {
  z-index: 1050;
}

.min-width {
  min-width: 32rem;
}
</style>
